import BaseService from "../base-service";
import RequestClient from "../request-client";

class ConversationCommandService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "conversation-commands",
        });
    }

    executeCallback(ConversationCommandId, payload) {
        return RequestClient.post(`/api/conversation-commands/${ConversationCommandId}/callback`, payload);
    }
}

export default new ConversationCommandService();
