<template>
    <v-main>
        <template v-slot:default>
            <v-section class="h-full">
                <template v-if="isLoading">
                    <v-loader />
                </template>

                <template v-else>
                    <div class="max-w-sm m-auto mt-12 border border-primary-500">
                        <div class="flex flex-col space-y-default p-8">
                            <v-form-select
                                v-model="transaction.order_line_id"
                                :options="jobSelectOptions"
                                default-label="Select Job"
                            />
                            <v-text v-if="transaction.order_line_id && selectedJobPrimary">
                                You are about to tip <v-link class="inline" :to="{ name: 'users.show', params: { user: selectedJobPrimary.user_id } }">
                                    {{ selectedJobPrimary.name }}
                                </v-link> for a job well done on this job. Please enter the amount below and proceed to payment.
                            </v-text>
                            <v-form-group>
                                <v-form-label>Amount</v-form-label>
                                <v-form-input v-model="transaction.amount" type="number" name="amount" :disabled="isLoading" />
                            </v-form-group>
                            <v-form-group>
                                <v-form-label>Card Number</v-form-label>
                                <v-form-input v-model="transaction.card_number" name="card_number" maxlength="19" :disabled="isLoading" />
                            </v-form-group>
                            <v-form-group>
                                <v-form-label>CVC</v-form-label>
                                <v-form-input v-model="transaction.cvc" name="cvc" maxlength="3" :disabled="isLoading" />
                            </v-form-group>
                            <v-form-group>
                                <v-form-label>Expiration (MM/YY)</v-form-label>
                                <v-form-input v-model="transaction.expiration" name="expiration" maxlength="5" :disabled="isLoading" />
                            </v-form-group>
                            <v-form-input v-model="transaction.stripeToken" class="hidden" />
                            <v-button @click="stripePay()">
                                Pay now
                            </v-button>
                        </div>
                    </div>
                </template>
            </v-section>
        </template>
    </v-main>
</template>

<!-- <script src="https://js.stripe.com/v3/"></script> -->
<script>
import Collect from "collect.js";
import Main from "@/layouts/panel/main.vue";
import ConversationCommand from "@/services/modules/conversation-command-service";
import UserService from "@/services/modules/user-service";

export default {
    components: {
        "v-main": Main,
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            jobs: [],

            /**
             * @todo Write a description.
             */
            jobSelectOptions: [],

            /**
             * @todo Write a description.
             */
            transaction: {
                order_line_id: null,
                amount: 0,
                card_number: null,
                cvc: null,
                expiration: null,
                stripeToken: null,
            },
        };
    },
    computed: {
        selectedJobPrimary() {
            const sherpa = this.jobs.filter((job) => job.order_line_id === this.transaction.order_line_id)[0].primary_sherpa;

            return sherpa ?? {};
        },
    },
    watch: {
        "transaction.expiration": {
            handler(value) {
                if (value && value.length === 2) {
                    this.transaction.expiration = `${value}/`;
                }
            },
            immediate: true,
            deep: true,
        },
        "transaction.card_number": {
            handler(value, oldValue) {
                if (value && value.length > 16) {
                    console.log(oldValue, this.transaction.card_number);
                    this.transaction.card_number = oldValue;
                }
            },
            immediate: true,
            deep: true,
        },

    },
    created() {
        UserService.getJobs(this.$me.getUser().user_id)
            .then((response) => {
                this.jobs = response.data.data;

                const jobs = Collect(this.jobs)
                    .sortBy("order_line_id")
                    .transform((item) => ({ value: item.order_line_id, label: `Job #${item.order_line_id}` }));

                this.jobSelectOptions = [
                    {
                        label: "Select Job",
                        value: null,
                    },
                    ...jobs,
                ];
            });
    },
    methods: {
        stripePay() {
            // https://stripe.com/docs/payments/quickstart
            // const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
            // var paymentElement = elements.create("payment");
            this.toggleLoading();

            ConversationCommand.executeCallback(this.$route.params.conversation_command, this.transaction)
                .then((response) => {
                    console.log(response.data);
                    this.transaction = {
                        order_line_id: null,
                        amount: 0,
                        card_number: null,
                        cvc: null,
                        expiration: null,
                        stripeToken: null,
                    };
                })
                .finally(() => {
                    this.toggleLoading();
                    this.$router.push({ name: "conversations.index" });
                });
        },
    },
};
</script>
